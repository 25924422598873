import React from "react"
import css from "@styled-system/css"

import { Box, Flex, H3, Text } from "components"

const Department = ({ label, description, children }) => (
  <Box
    pt={4}
    borderTop="1px solid"
    borderColor="border"
    css={css({
      "&:not(:last-of-type)": {
        mb: [4, 5],
      },
    })}
  >
    <Flex
      flexWrap="wrap"
      css={css({
        m: [-2, -4],
        "> *": {
          p: [2, 4],
        },
      })}
    >
      <Box width={["100%", 1 / 2]}>
        <H3>{label}</H3>
      </Box>
      <Box width={["100%", 1 / 2]}>
        <Text mb={4}>{description}</Text>
        {children}
      </Box>
    </Flex>
  </Box>
)

export default Department
