import React from "react"
import css from "@styled-system/css"

import { Box, H1, Text, Wrapper } from "components"

import Department from "./_department"
import Role from "./_role"

const Opportunities = ({ title, description, categories }) => (
  <Wrapper as="section" id="opportunities" pt={[5, 6]}>
    {title && <H1>{title.text}</H1>}
    {description && (
      <Text
        mt={[3, 4]}
        fontSize={[2, 3, 4]}
        css={css({
          maxWidth: 600,
        })}
      >
        {description.text}
      </Text>
    )}
    {categories && (
      <Box pt={5}>
        {categories.map((category, index) => (
          <Department
            label={
              category.primary.category_title &&
              category.primary.category_title.text
            }
            description={
              category.primary.category_description &&
              category.primary.category_description.text
            }
            key={"department" + index}
          >
            {category.items &&
              category.items.map((item, index) => (
                <Role
                  label={item.career}
                  location={item.location1}
                  description={item.description}
                  apply={item.application_link}
                  file={item.career_pdf}
                  key={"role" + index}
                />
              ))}
          </Department>
        ))}
      </Box>
    )}
  </Wrapper>
)

export default Opportunities
