import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { CallToAction, PageHeader, SEO } from "components"
import { usePreviewData } from "utils"

import Opportunities from "./_opportunities"

function CareersPage() {
  // Static query
  const data = useStaticQuery(
    graphql`
      {
        prismicCareers {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
            culture_image {
              url
              alt
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            culture_video {
              url
            }
            culture_title {
              text
            }
            culture_description {
              html
            }
            culture_button_text
            culture_button_link {
              link_type
              url
            }
            culture_style
            culture_pattern
            culture_color
            culture_side
            careers_title {
              text
            }
            careers_description {
              text
            }
            body {
              ... on PrismicCareersBodyCareerCategory {
                id
                items {
                  career
                  location1
                  description {
                    html
                  }
                  application_link {
                    url
                    link_type
                  }
                  career_pdf {
                    url
                    link_type
                  }
                }
                primary {
                  category_description {
                    text
                  }
                  category_title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicCareers } = MERGED_DATA

  return (
    <>
      <SEO
        title={prismicCareers.data.seo_title}
        description={prismicCareers.data.seo_description}
        image={prismicCareers.data.seo_image}
      />
      <PageHeader
        image={prismicCareers.data.hero_image}
        video={prismicCareers.data.hero_video}
        title={prismicCareers.data.hero_title}
        subtitle={prismicCareers.data.hero_subtitle}
      />
      <CallToAction
        image={prismicCareers.data.culture_image}
        video={prismicCareers.data.culture_video}
        heading={prismicCareers.data.culture_title}
        text={prismicCareers.data.culture_description}
        button={prismicCareers.data.culture_button_text}
        link={prismicCareers.data.culture_button_link}
        style={prismicCareers.data.culture_style}
        pattern={prismicCareers.data.culture_pattern}
        bg={prismicCareers.data.culture_color}
        align={prismicCareers.data.culture_side}
      />
      <Opportunities
        title={prismicCareers.data.careers_title}
        description={prismicCareers.data.careers_description}
        categories={prismicCareers.data.body}
      />
    </>
  )
}

export default CareersPage
