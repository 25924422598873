import React from "react"
import css from "@styled-system/css"

import { Box, Flex, H5, PrismicLink, RichText, Text } from "components"

const Role = ({ label, location, description, file, apply }) => (
  <Box
    css={css({
      pt: 4,
      borderTop: "1px solid",
      borderColor: "border",
      "&:not(:last-of-type)": {
        mb: 4,
      },
    })}
  >
    <H5>{label}</H5>
    <Text>{location}</Text>
    <RichText content={description} mt={2} />
    <Flex mt={1}>
      {apply && (
        <Text mr={file && 2}>
          <PrismicLink link={apply} newTab>
            Apply
          </PrismicLink>
        </Text>
      )}
      {file && (
        <Text>
          <PrismicLink link={file} newTab>
            Details
          </PrismicLink>
        </Text>
      )}
    </Flex>
  </Box>
)

export default Role
